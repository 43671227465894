import React, { Component } from 'react'

import SEO from '../components/seo'

class NotFoundTemplate extends Component {

  render() {
    return (
      <>
        <SEO title={'Page Not Found - 404'} bodyClass={'404'} description={''} />

        <section className='module__large-center-text'>
          <div className='module__large-center-text__inner'>
            <h1>404 Not Found</h1>
          </div>
        </section>
      </>
    )
  }
}

export default NotFoundTemplate
